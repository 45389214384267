@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

body {
  margin: 0;
  font-family: "Montserrat", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.hemisphere {
  border-bottom-left-radius: 50%;
  border-bottom-right-radius: 50%;
  background: linear-gradient(180deg, #F9940C 0%, #F9B70C 100%), 
              linear-gradient(180deg, #FFC107 0%, #284B63 100%);
  background-blend-mode: overlay;
  opacity: .2;
}
