/* styles.css or equivalent */
.video-container {
    position: relative;
    /* width: 50%; */
    overflow: hidden;
  }
  
  .video-thumbnail {
    width: 100%;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
  }
  
  .video-expanded {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
    height: auto;
    z-index: 1000;
  }

  .custom-list {
    list-style-type: disc;
    padding-left: 1em;
  }
  
  
